<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span>
            <label>완료 일시</label>
            <date-picker
              v-model="search.startDate.value"
              :max-date="new Date()"
              @input="fetch(1)"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.endDate.value"
              :max-date="new Date()"
              :min-date="search.startDate.value"
              @input="fetch(1)"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
          <span>
            <!--
            <label>검색어</label>
            <base-select
              :options="searchTypeOptions"
              v-model="search.type"
              placeholder="선택"
            />
            <input
              type="text"
              v-model="search.q"
              placeholder="검색어를 입력해 주세요."
            />
             -->
          </span>
        </div>
      </form>
    </section>

    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색:</span>
            <span class="total_count">{{ searchTotalItems }}</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
            placeholder="선택"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            :isEdit="false"
            @sort="onSortClick"
            :clickedNum="clickedNum"
          >
          </base-table>
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
      <!--
      <div class="btn_right_wrapper">
        <base-button
          type="download"
          @click.prevent.stop="
            download(
              'advertisement?download=xls',
              '일반 미션별 완료 현황',
              'xls'
            )
          "
          >엑셀 다운로드</base-button
        >
      </div>
      -->
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseSelect from "@/components/core/BaseSelect.vue";
// import BaseButton from "@/components/core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import Dialog from "@/components/Dialog.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "MissionMissionCompleteGroupPresentGeneral",
  components: {
    BaseSelect,
    // BaseButton,
    BaseTable,
    BasePagination,
  },
  data() {
    return {
      currentSort: "",
      clickedNum: 0,
      searchTotalItems: 0,
      fields: [
        {
          key: "user.name",
          width: "150px",
          label: "운영기관",
        },
        {
          key: "mission.name",
          width: "200px",
          label: "미션 명",
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "reviewScoreAvg",
          width: "150px",
          label: "미션만족도",
        },
        {
          key: "deliveryCount",
          width: "150px",
          label: "배송수령",
          formatter: {
            name: "number",
          },
        },
        {
          key: "selfCount",
          width: "150px",
          label: "직접수령",
          formatter: {
            name: "number",
          },
        },
        {
          key: "complteCount",
          width: "150px",
          label: "완료수",
          formatter: {
            name: "number",
          },
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "complteTotalCount",
          width: "150px",
          label: "누적 완료수",
          formatter: {
            name: "number",
          },
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      searchTypeOptions: [
        { name: "이름", value: "userName" },
        { name: "아이디", value: "userLoginid" },
        { name: "미션 명", value: "name" },
        { name: "이메일", value: "email" },
      ],
      search: {
        type: "",
        q: "",
        startDate: {
          // event: true,
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          // event: true,
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
      },
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    download(url, name, type, params) {
      params = {
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/mission/complete/general`;
      File.download(url, name, type, params);
    },
    onSearch(event) {
      event.preventDefault();
      // console.log(this.search.type);
      // console.log(this.search.q);
      this.fetch(1);
    },

    onSortClick(value) {
      for (let i = 0; i < this.fields.length; i++) {
        this.fields[i].clickedNum = 0;
      }

      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.clickedNum = this.clickedNum < 2 ? this.clickedNum + 1 : 0;
      this.fields[value.index].clickedNum = this.clickedNum;
      if (!this.contentLoading) {
        this.fetch(1);
      }
    },

    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };

      if (this.currentSort) {
        if (this.clickedNum == 1) {
          params.sort = `${this.currentSort}.asc`;
        } else if (this.clickedNum == 2) {
          params.sort = `${this.currentSort}.desc`;
        }
      }

      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");

        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }

      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
          }
        }
      });

      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      } else if (0 < this.search.q.length) {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
      }

      await client.missionCompleteGroupGeneralFindAll(params).then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            if (item.reviewScoreAvg == 100) {
              item.reviewScoreAvg = "★★★★★";
            } else if (item.reviewScoreAvg >= 90) {
              item.reviewScoreAvg = "★★★★☆";
            } else if (item.reviewScoreAvg >= 80) {
              item.reviewScoreAvg = "★★★★";
            } else if (item.reviewScoreAvg >= 70) {
              item.reviewScoreAvg = "★★★☆";
            } else if (item.reviewScoreAvg >= 60) {
              item.reviewScoreAvg = "★★★";
            } else if (item.reviewScoreAvg >= 50) {
              item.reviewScoreAvg = "★★☆";
            } else if (item.reviewScoreAvg >= 40) {
              item.reviewScoreAvg = "★★";
            } else if (item.reviewScoreAvg >= 30) {
              item.reviewScoreAvg = "★☆";
            } else if (item.reviewScoreAvg >= 20) {
              item.reviewScoreAvg = "★";
            } else if (item.reviewScoreAvg >= 10) {
              item.reviewScoreAvg = "☆";
            } else {
              item.reviewScoreAvg = "-";
            }
          });
          if (this.search.startDate.value && this.search.endDate.value) {
            this.searchTotalItems = data.totalItems;
          }

          this.items = data.items;
          if (this.totalItems == 0) {
            this.totalItems = data.totalItems;
          }
          this.totalPages = data.totalPages;

          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          // this.totalItems = 0;
          this.totalPages = 0;
          this.searchTotalItems = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
